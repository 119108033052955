.departmentCard {
   background: #4DA2A8 0% 0% no-repeat padding-box;
   border-color: #4DA2A8;
   color: #FFFFFF;
   opacity: 1;
}

.partyCard {
   background: #D35050 0% 0% no-repeat padding-box;
   border-color: #D35050;
   color: #FFFFFF;
   opacity: 1;
}

.alliancesCard {
   background: #917BD3 0% 0% no-repeat padding-box;
   border-color: #917BD3;
   color: #FFFFFF;
   opacity: 1;
}

.oppositionCard {
   background: #FF9E58 0% 0% no-repeat padding-box;
   border-color: #FF9E58;
   color: #FFFFFF;
   opacity: 1;
}