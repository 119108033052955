.splitter {
    border: 1px solid #CCC6C6;
}

.departmentCount{
   background: #4DA2A8 0% 0% no-repeat padding-box;
   border-radius: 4px;
   opacity: 1;
   color: #FFFFFF;
}
.partyCount {
    background: #D35050 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    color: #FFFFFF;
}
.alliancesCount {
    background: #917BD3 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    color: #FFFFFF;
}
.oppositionCount {
    background: #FF9E58 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    color: #FFFFFF;
}