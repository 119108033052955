body{
   background-color: #f2f6ff;
   width: 100%;
   height: 100%;
}

.heading-color{
    color: #2780e3;
}

