
.button {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #7070704D;
    opacity: 1;    
}

.button:hover{
    background: #5154d3 0% 0% no-repeat padding-box;    
}

.calendarBtnActive {
    background: #5154d3 0% 0% no-repeat padding-box !important;
    color: #FFFFFF;
}


.dropdown {
    background: #0000 0% 0% no-repeat padding-box;
    border: 5px solid #7070705F;
    border-radius: 4px;
    opacity: 1;
}



.mapButton {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-left: 1px solid #7070704D;
    border-right: 1px solid #7070704D;
    opacity: 1;
}

.mapButton:hover {
    background: #5154d3 0% 0% no-repeat padding-box;
}

.mapButton:active {
    background: #5154d3 0% 0% no-repeat padding-box;
}

.resetButton {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #7070704D;    
    opacity: 1;
}
.resetButton:hover {
    background: #5154d3 0% 0% no-repeat padding-box;
}

$bg-color: rgb(255, 255, 255);
$txt-color: rgb(11, 11, 11);

.dark{
    --bg-color: rgb(25, 38, 46);
    --txt-color: rgb(255, 255, 255);
}

.calendarPage .rmsc{
    width: 13rem !important;
    --rmsc-hover: #f1f3f5 !important;
    --rmsc-gray: #aaa !important;
    --rmsc-p: 1rem !important;
    --rmsc-radius: 0.5rem !important;
    --rmsc-h: 2rem !important;
    
    
    .dropdown-container{
        background-color: var(--bg-color, rgb(255, 255, 255)) !important;
        color: var(--txt-color, rgb(11, 11, 11)) !important;        
        border-color: #7070705F !important;
        width: 13rem !important;
    }
    
    .panel-content{
        background-color: var(--bg-color, rgb(255, 255, 255)) !important;
        border: #7070705F !important;
        
        ::-webkit-scrollbar{
            width: 1px !important;
        }
        
    }
    .select-panel{
        background-color: var(--bg-color, rgb(255, 255, 255)) !important;
        border: #7070705F !important;
    }

    .search input{
       background-color: var(--bg-color,rgb(255, 255, 255)) !important;       
    }        
    
    .select-item{
        background-color: var(--bg-color,rgb(255, 255, 255)) !important;        
    }
    .select-item:hover {
        background-color: #7070705F !important;
    }
}
