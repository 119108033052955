.header {
    border-radius: 8px;
    position: sticky;
    top: 0;
    z-index: 99;
}

::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.date-input {
    background: transparent !important;
    color: black !important;
    border-radius: .5rem; 
}