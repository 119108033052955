@tailwind base;
@tailwind components;
@tailwind utilities;


/*reset rules or default styles applied to plain HTML elements*/

/*class-based styles that you want to be able to override with utilities*/
@layer components {
    
    .card {
        background: #FFFFFF 0% 0% no-repeat padding-box !important;
        box-shadow: 0px 2px 6px #0000001F;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        opacity: 1;
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);
    }

    .darkCard {
        background: #19262e 0% 0% no-repeat padding-box !important;
        box-shadow: 0px 2px 6px #0000001F;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        opacity: 1;
        border-radius: 10px;
        border: 1px solid rgba(255, 255, 255, 0.18);
    }

    .summaryCard {
        background: #FFFFFF 0% 0% no-repeat padding-box !important;
        border: 1px solid #CCC6C6;
        border-radius: 12px;
        opacity: 1;
    }
    
    .darkSummaryCard {
        background: #19262e 0% 0% no-repeat padding-box !important;
        border: 1px solid rgb(204, 198, 198, 0.41);
        border-radius: 12px;
        opacity: 1;
    }
}
/*single-purpose classes that should always take precedence over any other styles*/


html {
    font-size: clamp(11px, .8vw, 18px);  
    // background: #D9DEE6;  
}

.dark {
    background-color: #01121c;
}

body::-webkit-scrollbar {
    width: 0rem;
}

body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}


.statusIcon {
    top: 1rem;
    left: 1rem;
    width: 1rem;
    height: .5rem;
    border-radius: 0.2rem;
    opacity: 1;
}

.bgPositive {
    background: #37BE46 0% 0% no-repeat padding-box;
    border: 0.4rem solid #37BE46;
}

.bgNegative {
    background: #D95555 0% 0% no-repeat padding-box;
    border: 0.4rem solid #D95555;
}

.bgNeutral {
    background: #E7A73F 0% 0% no-repeat padding-box;
    border: 0.4rem solid #E7A73F;
}

.bgGov {
    background: #9C68D8 0% 0% no-repeat padding-box;
    border: 0.4rem solid #9C68D8;
}

.bgCM {
    background: #F0A600 0% 0% no-repeat padding-box;
    border: 0.4rem solid #F0A600;
}

.bgAdmk {
    background: #398D43 0% 0% no-repeat padding-box;
    border: 0.4rem solid #398D43;
}

.bgBjp {
    background: #B9632E 0% 0% no-repeat padding-box;
    border: 0.4rem solid #B9632E;
}

.bgOthers {
    background: #22B4F4 0% 0% no-repeat padding-box;
    border: 0.4rem solid #22B4F4;
}
