
.topTrending {
    ::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid #C5C5F4;
        border-radius: 10px;
        height: 12px;
    }
    ::-webkit-scrollbar {
        width: 10px !important;
        height: 12px;
    }    
    ::-webkit-scrollbar-track {
        border: transparent !important;
    }
}

.positiveBtnActive{
    background-color: #37BE46;
    color: white !important;
}

.negativeBtnActive{
    background-color: #D95555;
    color: white !important;
}

$bg-color: rgb(255, 255, 255);
$txt-color: rgb(11, 11, 11);

.dark {
    --bg-color: rgb(25, 38, 46);
    --txt-color: rgb(255, 255, 255);
}

$width: 200px;

@media screen and (max-width: 499px) {
.rmsc{--width : 70px;}
}

@media screen and (min-width: 500px) and (max-width: 530px) {
    .rmsc {
        --width: 80px;
    }
}

@media screen and (min-width: 690px) and (max-width: 860px) {
    .rmsc {
        --width: 120px;
    }
}

@media screen and (min-width: 861px) and (max-width: 1158px) {
    .rmsc {
        --width: 14rem;
    }
}

.rmsc {
    width: var(--width, 9.5rem) !important;
    --rmsc-hover: #f1f3f5 !important;
    --rmsc-gray: #aaa !important;
    --rmsc-p: 1rem !important;
    --rmsc-radius: 0.5rem !important;
    --rmsc-h: 2rem !important;
    direction: left !important;
    --rmsc-main: transparent !important;
    

    .dropdown-container {
        background-color: var(--bg-color, rgb(255, 255, 255)) !important;
        color: var(--txt-color, rgb(11, 11, 11)) !important;
        border-color: transparent !important;
z-index: 999 !important;
        width: 100% !important;
    }

    .panel-content {
        background-color: var(--bg-color, rgb(255, 255, 255)) !important;
        border: #7070705F !important;

        ::-webkit-scrollbar {
            width: 1px !important;
        }
z-index: 999 !important;
    }

    .select-panel {
        background-color: var(--bg-color, rgb(255, 255, 255)) !important;
        border: #7070705F !important;
z-index: 999 !important;
    }

    .search input {
        background-color: var(--bg-color, rgb(255, 255, 255)) !important;
z-index: 999 !important;
    }

    .select-item {
z-index:999 !important;
        background-color: var(--bg-color, rgb(255, 255, 255)) !important;
    }

    .select-item:hover {
        background-color: #7070705F !important;
    }
}